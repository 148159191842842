import React, { useState } from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player"
import Layout from "../../components/layout"
import Modal from "../../pages/modal"
import Hopping from "../../img/hopping_pa_vatnet.jpg"
import Hopping2 from "../../img/hopping_pa_vatnet_2.jpg"

import Kano from "../../img/kano.jpg"
import Kano2 from "../../img/kano_ørret.jpg"

export default function Vannsport() {
  const [show, setShow] = useState(false)
  const [title, setTitle] = useState("")
  return (
    <div>
      <Modal show={show} onClose={() => setShow(false)} title={title} />
      <Layout>
        <div>
          <img
            className="h-48 md:h-60 lg:h-80 2xl:h-96 w-full object-cover object-center rounded-lg shadow-lg mt-6"
            src={Hopping}
            alt="Hopping på vatnet i Kjøsnesfjorden"
          />
          <h1 className="text-3xl text-blue-700 text-center mt-12 font-bold ">
            Vannsport
          </h1>
        </div>

        <div className="grid lg:grid-cols-2 gap-16 mt-12 mb-12">
          {/* Hopping på vatnet */}
          <div className="">
            <img
              className="w-2/3 block mx-auto rounded-lg shadow-lg"
              src={Hopping2}
              alt="Hopping på vatnet i Kjøsnesfjorden"
            />

            <h3 className="my-4 text-blue-400 font-semibold text-xl text-center">
              Hopping på vatnet på Sægrov
            </h3>

            <p className="mt-3">
              På Sægrov i Kjøsnesfjorden har vi laga eit hopp med overrenn nede
              ved vatnet slik at det er mogleg å hoppe med ski på vatnet om
              sommaren. Om de er ein gjeng som ønskjer å teste dette så er
              prisen kr. 300,- per person som hoppar. Det er og mogleg å låne
              skiutstyr og våtdrakt for tillegg i prisen.
            </p>

            <button
              onClick={() => {
                setTitle("leige av hoppanlegget på Sægrov")
                setShow(true)
              }}
              class="mb-3 block mx-auto text-green-50 px-3 py-1 rounded-md bg-gray-600 hover:bg-gray-800 w-80 mt-6"
            >
              Ta kontakt for å bruke hoppanlegget
            </button>

            <h4 className="mt-6 font-bold text-lg">Festival i august</h4>
            <p>
              Vi arrangerer og ein festival med skihppping på Jølstravatnet på
              Sægrov på slutten av sommaren. Der samlast vi og konkurrerer om
              kven som får dei "beste" skihoppa på Jølstravatnet i "skianlegget
              på Sægrov". Sjå film frå hoppinga i 2018 i videoen under.
            </p>
            <div className="flex flex-row justify-center">
              <Link
                to="/vannsport/hopping-pa-kjosnesfjorden/"
                class="inline-block  mb-12 text-green-50 px-3 py-1 px-3 rounded-md bg-blue-500 hover:bg-blue-700 mt-3"
              >
                Meir info
              </Link>
            </div>

            <div className="shadow-2xl">
              <ReactPlayer
                width="100%"
                controls="true"
                url="https://www.youtube.com/watch?v=NGJVaojVQF8"
                playing="true"
              />
            </div>
          </div>

          {/* Kanopadling på Håheimsvatnet */}
          <div className="">
            <img
              className="w-2/3 block mx-auto rounded-lg shadow-lg"
              src={Kano}
              alt="Hopping på vatnet i Kjøsnesfjorden"
            />

            <h3 className="my-4 text-green-700 font-semibold text-xl text-center">
              Kanopadling på Håheimsvatnet
            </h3>
            <p className="mt-3">
              Vi har ein Bergans Ally 18' kano med plass til 4 personar som de
              kan leige på Håheimsvatnet. Pris er kr. 300,- pr. tur.
              Redningsvest inkludert i prisen.
            </p>
            <p className="mt-3">
              Frå Skei skule og inn til Grungen er det samanhengande små vatn
              der det er mogleg å padle med kano. Nokre av kanalane mellom vatna
              er litt gjengrodd med buskar og trær, men det er mogleg å padle
              mellom fleire av dei.
            </p>
            <p className="mt-3">
              Alle vatna på strekninga er og fine fiskevatn om de vil prøve
              fiskelukka.
            </p>
            <button
              onClick={() => {
                setTitle("leige av Ally 18' kano")
                setShow(true)
              }}
              class="block mx-auto content-center mb-12  mt-6 text-green-50 px-3 py-1 rounded-md bg-green-600 hover:bg-green-800 w-80"
            >
              Ta kontakt for leige av kano
            </button>
            <img
              className="h-64 mt-6 w-4/5 mx-auto rounded-lg shadow-lg"
              src={Kano2}
              alt="Kano og ørret ved Håheimsvatnet"
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}
